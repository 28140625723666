<template lang="pug">
   div.d-flex.flex-column
    TableV2(
      :headers="headers"
      :items="items.results"
      :pageCount="items.count"
      :currentPage="items.current"
      :querySearch="getDataReport"
      :isLoading="isLoading"
      :saveReport="downloadReportExcel"
      :actions="actions"
    )
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      isLoading: true,
      headers: [
        { value: 'sailor.id',
          text: this.$i18n.t('tableHeaders.sailor_id'),
          sortable: false
        },
        { value: `sailor.full_name_ukr`,
          text: this.$i18n.t('tableHeaders.sailor_full_name'),
          sortable: false
        },
        { value: 'sailor.birth_date',
          text: this.$i18n.t('tableHeaders.sailor_date_birth'),
          sortable: false
        },
        { value: 'number',
          text: this.$i18n.t('tableHeaders.number'),
          sortable: true
        },
        { value: 'name_book',
          text: this.$i18n.t('tableHeaders.name_book'),
          sortable: false
        },
        { value: 'created_at',
          text: this.$i18n.t('tableHeaders.created_at'),
          sortable: true
        },
        { value: 'date_issued',
          text: this.$i18n.t('tableHeaders.date_issued'),
          sortable: true
        },
        { value: 'branch_office',
          text: this.$i18n.t('tableHeaders.branch_office'),
          sortable: true
        },
        { value: 'status_document',
          text: this.$i18n.t('tableHeaders.status_document'),
          sortable: true
        },
        { value: 'event',
          text: this.$i18n.t('tableHeaders.actions'),
          sortable: false
        }
      ],
      actions: [
        { id: 1, tooltip: 'tooltip.go', action: (item) => this.goToSailorSRB(item), color: 'blue', name: 'mdi-arrow-right' }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.publishedSRBs,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  methods: {
    ...mapActions(['getReportPublishedSRB', 'getReportPublishedSRBExcel']),
    async getDataReport (filter) {
      this.isLoading = true
      await this.getReportPublishedSRB(filter)
      this.isLoading = false
    },
    async downloadReportExcel (params) {
      await this.getReportPublishedSRBExcel(params)
    },
    goToSailorSRB (item) {
      item && this.$router.push({ path: `/sailor/${item.sailor.id}/experience/records/${item.id}` })
    }
  }
}
</script>
